/* eslint-disable */

<template>
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
};
</script>
<style lang="scss">
@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1270px !important;
  }
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
@import "node_modules/bootstrap/scss/bootstrap";
@import "~@/assets/styles/_application.scss";

#app {
  height: 100%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #000000;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.cookie_button {
  position: fixed;
  z-index: 99;
  right: 0;
  bottom: 0;
}

@media (max-width: 992px) {
  .heading {
    font: normal normal 600 24px/34px Montserrat !important;
  }
  .subHeading {
    font: normal normal 500 20px/19px Montserrat !important;
  }
  .desktopOnly {
    display: none;
  }
}
</style>
