import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import store from './store'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import linkify from "vue-linkify";

Vue.directive("linkified", linkify);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
